import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-root" }
const _hoisted_2 = {
  id: "vary_auth",
  class: "login login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
}
const _hoisted_3 = { class: "d-flex login-content order-2 order-lg-1 flex-column position-relative p-7 overflow-hidden" }
const _hoisted_4 = { class: "d-flex flex-column-fluid flex-center mt-20 mt-md-30 mt-lg-0" }
const _hoisted_5 = { class: "login-aside aside-img order-1 order-lg-2 flex-row-fluid p-10 p-lg-15" }
const _hoisted_6 = { class: "d-flex flex-row-fluid flex-column justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Logo, {
            width: 150,
            "text-color": "#fff",
            "dot-color": "#1A73E8"
          })
        ])
      ])
    ])
  ]))
}