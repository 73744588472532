
  import { defineComponent, computed, onMounted, onUnmounted } from 'vue'
  import { useStore } from 'vuex'
  import Logo from '@src/components/core/Logo.vue'
  import { Actions } from '@src/store/enums/StoreEnums'

  export default defineComponent({
    name: 'Auth',
    components: { Logo },
    setup () {
      const store = useStore()
      const errors = computed(() => store.state.auth.errors)
      const backgroundImage = computed(() => `${ process.env.BASE_URL }media/img/vary_mockup.png`)

      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'bg-body')
      })

      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'bg-body')
      })

      return {
        errors,
        backgroundImage
      }
    }
  })
